import React, { useContext } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DataContext } from "../../contexts/DataContext";
import { Button, NewsletterBox } from "@3beehivetech/components";
import useNavigate from "../../hooks/useNavigate";
import {
  StyledHero, StyledHeroBackground, StyledHeroBallon, StyledBackgroundImage, StyledHeroButtons, StyledHeroMainButtons, StyledNewsletter
} from "./style";
import {
  StyledParagraph, StyledSpacer, StyledContainer, StyledSubTitle, StyledTitleHero, StyledTextButtonBig
} from "../globalstyle";
import Balloon from "../Balloon/Balloon";
import { toast } from "react-toastify";
import useNewsletterListId from "../../hooks/useNewsletterListId";

const HeroVideo = (props) => {
  const { getListId } = useNewsletterListId();
  toast.configure()
  const { languages, pageImages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const imageData = pageImages.find(e => e.path === props.image);

  return (
    <StyledHero beethechange={props.beethechange}>
      {imageData && (
        <StyledBackgroundImage>
          <GatsbyImage loading="eager" image={getImage(imageData)} alt={imageData.alt} />
        </StyledBackgroundImage>
      )}
      {props.video && (
        <>
          <StyledHeroBackground>
            {props.showOverlay && (
              <div style="height: 100%; width: 100%; background: rgba(0, 0, 0, 0.5); position: absolute;" />
            )}
            <video muted autoPlay loop playsInline poster={props.posterVideo}>
              <source src={props.video} type="video/mp4" />
              <source src={props.videoWebm} type="video/webm" />
            </video>
          </StyledHeroBackground>

          <StyledHeroBackground mobile>
            {props.showOverlay && (
              <div style="height: 100%; width: 100%; background: rgba(0, 0, 0, 0.5); position: absolute;" />
            )}
            <video muted autoPlay loop playsInline poster={props.posterVideo}>
              <source src={props.videoMobile ? props.videoMobile : props.video} type="video/mp4" />
            </video>
          </StyledHeroBackground>
        </>
      )}
      <StyledHeroBallon color={props.theme} direction={props.direction ? props.direction : "right"}>
        <StyledContainer>
          {props.text && props.text.title && (
            <Balloon direction={props.direction ? props.direction : "right"}>
              <StyledTitleHero big dangerouslySetInnerHTML={{ __html: props.text.title }} />
              <div style="height: 5px" />
              <StyledSubTitle big dangerouslySetInnerHTML={{ __html: props.text.subtitle }} />
            </Balloon>
          )}
          {props.text && props.text.paragraph
            && <>
              <StyledParagraph small color="white" dangerouslySetInnerHTML={{ __html: props.text.paragraph }} />
              <StyledSpacer size={4} />
            </>
          }
          {props.text && props.text.cta
            && <StyledHeroMainButtons>
              <Button onClick={useNavigate(props.to)}><StyledTextButtonBig>{props.text.cta}</StyledTextButtonBig></Button>
              <StyledSpacer size={8} />
            </StyledHeroMainButtons>
          }
        </StyledContainer>

      </StyledHeroBallon>
      {props.cta2 && props.inputProps && (
        <StyledNewsletter>
          <NewsletterBox
            title={props.titleBox}
            newsletterListId={getListId(props.newsletterListType)}
            newsletterTitle={props.titleNewsletter}
            newsletterButtonText={props.newsLetterCtaTitle}
            lang={currentLanguageCode}
            leftButtonProps={{
              text: props.cta2.text,
              onClick: useNavigate(props.cta2.to)
            }}
            inputProps={props.inputProps}
            onSuccess={() => toast.success("Controlla la tua mail", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
            onError={() => toast.error("Purtroppo c'è stato un errore nei nostri sistemi. Contattatci a info@3bee.com", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
          />
        </StyledNewsletter>
      )}
    </StyledHero>

  );
};

export default HeroVideo;

import React from "react";
import { StyledBalloon } from "./style";

const Balloon = ({ direction, children }) => (
  <StyledBalloon direction={direction}>

    <div>{children}</div>
  </StyledBalloon>
);

export default Balloon;

/*
<svg viewBox="0 0 95 62">
      <defs>
        <filter x="-30%" y="-20%" width="160%" height="140%" filterUnits="objectBoundingBox" id="a">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" in="shadowBlurOuter1" />
        </filter>
        <path d="M78.5-6.5v95h-62v-33c33.9 0 61.444-27.206 61.992-60.975L78.5-6.5z" id="b" />
      </defs>
      <g transform="matrix(0 1 1 0 6.5 -16.5)" fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" href="#b" />
        <use fill="#FFF" href="#b" />
      </g>
    </svg>
    */

import styled from "styled-components";
import { Rem, Colors, BP } from "../../commons/Theme";

export const StyledBalloon = styled.div`
  position: relative;
  margin-bottom: ${Rem(64)};
  border-radius: ${Rem(0)};

  @media (${BP.tablet}) {
    display: inline-block;
    margin-bottom: 0;
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: ${Rem(16)};
  }

  & > svg {
    width: ${Rem(95)};
    height: ${Rem(62)};
    overflow: visible;
    position: absolute;
    top: 100%;
    ${({ direction }) => {
    return (direction === "right")
      ? `
          right: ${Rem(-16)};
          transform: translateY(${Rem(-16)}) rotate(-90deg);
        `
      : `
          left: ${Rem(-16)};
          transform: translateY(${Rem(-16)}) scale(-1, 1) rotate(-90deg);
        `;
  }}
    z-index: 0;

    @media (${BP.tablet}) {
      top: auto;
      right: auto;
      bottom: 0;
      left: ${({ direction }) => (direction === "bottom") ? Rem(-16) : "auto"};
      ${({ direction }) => direction}: ${Rem(-62)};
      transform: ${({ direction }) =>
    (direction === "right") ? "scale(-1, 1)" : (direction === "bottom") ? `translateY(${Rem(-16)}) scale(-1, 1) rotate(-90deg)` : "none"
  };
    }
  }

  & > div {
    position: relative;
    z-index: 2;
  }
`;

import styled, { css } from "styled-components";
import { Rem, BP, Colors } from "../../commons/Theme";
import { StyledBalloon } from "../Balloon/style";
import { StyledParagraph, StyledContainer, StyledButton } from "../globalstyle";

export const StyledHero = styled.div`
  position: relative;
  margin-bottom: ${Rem(104)};
  padding-top:${Rem(100)};
  
  @media (${BP.tablet}) {
    display: flex;
    height: 90vh;
    max-height: 100%;
    align-items: center;
    padding-top:${Rem(0)};
  }

  ${(props) =>
    props.beethechange
    && css`
      display: flex;
      height: 100vh;
      max-height: 100%;
      align-items: center;
      padding-top:${Rem(0)};
      margin-top: 0;
      margin-bottom: -100vh;

      & video {
        object-position: center top !important;
        object-fit: contain !important;
        @media (min-width: 700px) {
          object-fit: cover !important;
        }
      }

      margin-top: ${Rem(64)};
      @media (min-width: 900px) {
        margin-top: 0;
      }
    `}
`;

export const StyledBackgroundImage = styled.div`
  position: absolute;
  top:0;
  left:0;
  z-index:0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .gatsby-image-wrapper  {
    position: initial !important;
  }

  > img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    position:absolute;
    @media (${BP.tablet}) {
      object-position: right center;
    }
  }
`;
export const StyledHeroBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: none;

  @media (${BP.tablet}){
    display: flex;
  }

  ${(props) =>
    props.mobile
    && css`
    display: flex;
    @media (${BP.tablet}){
      display: none;
    }
    `
  }

  div{
    width: 100%;
    object-fit: cover;
    object-position: center center; 
  }
  
  video {
    width: 100%;
    object-fit: cover;
    object-position: center center;

    @media (${BP.tablet}) {
      object-position: right center;
    }
  }
`;

export const StyledNewsletter = styled.div`
  position: absolute;
  display: flex;
  place-content: center;
  bottom: -45px;
  z-index: 0;
  width: 100%;
  @media (${BP.tablet}) {
    bottom: -35px;
  }
`

export const StyledHeroBallon = styled.div`
  margin: 0 auto;
  margin-top: ${Rem(10)};
  max-width: ${Rem(740)};
  color: ${(props) => props.color} !important;
  @media (${BP.tablet}) {
    position: absolute;
    margin-top: ${Rem(120)};
    ${({ direction }) =>
    direction === "right"
      ? `left: ${Rem(60)};`
      : direction === "bottom"
        ? `
            left: ${Rem(60)};
            bottom: ${Rem(80)};
          `
        : `right: ${Rem(60)};`
  }
  }

  ${StyledBalloon} {
    width: 100%;
    margin-bottom: ${Rem(48)};
  }

  ${StyledContainer} {
    padding-bottom: ${Rem(58)};
    padding-left: 5%;
    @media (${BP.tablet}) {
      padding: 0 ${Rem(32)};
      padding-left: ${Rem(60)};
    }
  }


  & > ${StyledContainer} > ${StyledParagraph} {
    text-shadow: 0 0 ${Rem(2)} rgba(0, 0, 0, 0.6);
  }

`;

export const StyledHeroButtons = styled.div`
  display: flex;
  gap: ${Rem(10)};
  
  @media (${BP.tablet}) {
    width: 48%;
    gap: auto;
    justify-content: space-between;
  }

  ${StyledButton} {
    margin-top: 0px;
    padding: ${Rem(10)} ${Rem(7)};
    font-size: ${Rem(14)};
    color: ${Colors.black};
    @media (${BP.tablet}) {
      margin-left:0;
    }
  }
`;

export const StyledHeroMainButtons = styled.div`
  ${StyledButton} {
    @media (${BP.tablet}) {
    width: 48%;
    }
  }
`;


